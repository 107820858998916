{
	const nav = document.querySelector(`.nav`);
	const navWrapper = document.querySelector(`.nav__wrapper`);

	let toggle = false;

	const checkScrollPos = () => {
		if(window.pageYOffset > 100)	{
			nav.classList.add("backgroundNav");
			nav.classList.add("backgroundNav");
			navWrapper.classList.add("smallNav");
		} else {
			nav.classList.remove("backgroundNav");
			nav.classList.remove("backgroundNav");
			navWrapper.classList.remove("smallNav");
		}
	}

	const init = () => {
		window.addEventListener(`scroll`, checkScrollPos);
		//checkScrollPos();
	};

	init();
}