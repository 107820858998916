import './_smoothScroll';
import './_cookieBanner';
import './_navBackground';
import './_responsiveNav';
import './_newsScroll';
import './_productFilter';
import './_waterCalculation';
import './_variantSelector';
import './_hideRentingOption';

{
  const init = () => {
    document.getElementById('year').innerHTML = new Date().getFullYear();
  };
  init();
}
