{
	const nav = document.querySelector(`.nav`);
	const navCenter = document.querySelector(`.nav__center`);
	const navRight = document.querySelector(`.nav__right`);
	const body = document.querySelector(`body`);

	const handleOpenMenu = () => {
		nav.classList.toggle("open");
		navCenter.classList.toggle("open");
		navRight.classList.toggle("open");
		body.classList.toggle("mobile__nav__open");

	}

	const init = () => {
		document.getElementById(`openNav`).addEventListener(`click`, handleOpenMenu);
	};

	init();
}