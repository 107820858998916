{
	handleChangeColorSelected = e => {
		const images = Array.from(document.querySelectorAll(".products__items__product__image"));
		const selectedVariant = e.currentTarget.value;

		images.forEach(img => img.classList.add('hidden'));

		const selectedImage = images.find(image => image.dataset.variant.includes(selectedVariant));
		selectedImage.classList.remove("hidden");
	}

	const init = () => {
		const colorSelector = document.getElementById('colorSelector');

		if(colorSelector){
			colorSelector.addEventListener(`change`, handleChangeColorSelected);
		}
	};

	init();
}