{
  const products = Array.from(document.querySelectorAll(".products__items__product"));


  const companyProducts = products.filter(product => product.dataset.sector.includes('companies'));
  const horecaProducts = products.filter(product => product.dataset.sector.includes('horeca'));
  const homeProducts = products.filter(product => product.dataset.sector.includes('home'));

  // const companyProducts = document.querySelectorAll(
  //   "[data-sector='companies']"
  // );
  // const horecaProducts = document.querySelectorAll("[data-sector='horeca']");
  // const homeProducts = document.querySelectorAll("[data-sector='home']");

  const allButton = document.getElementById('all');
  const companyButton = document.getElementById('companies');
  const horecaButton = document.getElementById('horeca');
  const homeButton = document.getElementById('particulieren');

  const filterProducts = (type) => {
    switch (type) {
      case 'all':
        companyProducts.forEach((i) => i.classList.remove('hidden'));
        horecaProducts.forEach((i) => i.classList.remove('hidden'));
        homeProducts.forEach((i) => i.classList.remove('hidden'));

        allButton.classList.add('activeSector');
        companyButton.classList.remove('activeSector');
        horecaButton.classList.remove('activeSector');
        homeButton.classList.remove('activeSector');
        break;
      case 'companies':
        console.log(companyProducts);
        horecaProducts.forEach((i) => i.classList.add('hidden'));
        homeProducts.forEach((i) => i.classList.add('hidden'));
        companyProducts.forEach((i) => i.classList.remove('hidden'));


        allButton.classList.remove('activeSector');
        companyButton.classList.add('activeSector');
        horecaButton.classList.remove('activeSector');
        homeButton.classList.remove('activeSector');
        break;
      case 'horeca':
        companyProducts.forEach((i) => i.classList.add('hidden'));
        homeProducts.forEach((i) => i.classList.add('hidden'));
        horecaProducts.forEach((i) => i.classList.remove('hidden'));


        allButton.classList.remove('activeSector');
        companyButton.classList.remove('activeSector');
        horecaButton.classList.add('activeSector');
        homeButton.classList.remove('activeSector');
        break;
      case 'particulieren':
        companyProducts.forEach((i) => i.classList.add('hidden'));
        horecaProducts.forEach((i) => i.classList.add('hidden'));
        homeProducts.forEach((i) => i.classList.remove('hidden'));

        allButton.classList.remove('activeSector');
        companyButton.classList.remove('activeSector');
        horecaButton.classList.remove('activeSector');
        homeButton.classList.add('activeSector');
        break;
    }
  };

  const init = () => {
    const allProducts = document.getElementById('all');
    const companies = document.getElementById('companies');
    const horeca = document.getElementById('horeca');
    const particulieren = document.getElementById('particulieren');

    const predefinedLocation = window.location.href.split('?for=')[1];

    if (predefinedLocation) {
      filterProducts(predefinedLocation);
    }

    const products = document.querySelector('.products');

    if (products) {
      allProducts.addEventListener(`click`, () => filterProducts('all'));
      companies.addEventListener(`click`, () => filterProducts('companies'));
      horeca.addEventListener(`click`, () => filterProducts('horeca'));
      particulieren.addEventListener(`click`, () =>
        filterProducts('particulieren')
      );
    }
  };

  init();
}
