{

	handleHideRentingOption = e => {
		console.log(e.currentTarget.value);
		if (e.currentTarget.value === "Bedrijf" || e.currentTarget.value === "Horeca") {
			document.querySelector('.label-buy-hire').classList.remove('hidden');
			document.querySelector('.checks-buy-hire').classList.remove('hidden');
		} else if (e.currentTarget.value === "Thuis") {
			document.querySelector('.label-buy-hire').classList.add('hidden');
			document.querySelector('.checks-buy-hire').classList.add('hidden');
		}
	}

	const init = () => {
		const sectors = Array.from(document.querySelectorAll('.offerte-sectorSelector'));

		if (sectors) {
			sectors.forEach(sector => sector.addEventListener(`change`, handleHideRentingOption))
		}
	};

	init();
}