{
  const pricePerLiterAqua = 0.03;

  const pricePerLiterVittel = 0.82;
  const pricePerLiterSpa = 0.81;
  const pricePerLiterChaudfontaine = 0.72;
  const pricePerLiterBru = 0.95;
  const pricePerLiterEvian = 0.78;
  const pricePerLiterPerrier = 0.89;
  const pricePerLiterSanPellegrino = 0.84;

  const calculateWater = (e) => {
    e.preventDefault();

    const waterAmount = document.getElementById('literInput').value;
    const waterBrand = document.getElementById('brand').value;

    const priceAqua = waterAmount * pricePerLiterAqua;
    let priceBrand;

    switch (waterBrand) {
      case 'vittel':
        priceBrand = waterAmount * pricePerLiterVittel;
        break;
      case 'spa':
        console.log("spatje");
        priceBrand = waterAmount * pricePerLiterSpa;
        break;
      case 'chaudfontaine':
        priceBrand = waterAmount * pricePerLiterChaudfontaine;
        break;
      case 'bru':
        priceBrand = waterAmount * pricePerLiterBru;
        break;
      case 'evian':
        priceBrand = waterAmount * pricePerLiterEvian;
        break;
      case 'perrier':
        priceBrand = waterAmount * pricePerLiterPerrier;
        break;
      case 'sanPellegrino':
        priceBrand = waterAmount * pricePerLiterSanPellegrino;
        break;
    }

    const monthly = priceBrand * 30 - priceAqua * 30;
    const yearly = priceBrand * 365 - priceAqua * 365;

    setValues(monthly, yearly);
    // animateValue('monthly', 0, monthly.toFixed(0), 2000);
    // animateValue('yearly', 0, yearly.toFixed(0), 1);
  };

  const setValues = (monthly, yearly) => {
    document.getElementById('monthly').innerHTML = '€' + monthly.toFixed(2);
    document.getElementById('yearly').innerHTML = '€' + yearly.toFixed(2);
  };

  function animateValue(id, start, end, duration) {
    if (start >= end) return;
    var range = 30 - start;
    var current = start;
    var increment = end > start ? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var obj = document.getElementById(id);
    var timer = setInterval(function () {
      current += increment;
      obj.innerHTML = '€' + current;
      if (current == end) {
        clearInterval(timer);
      }
    }, stepTime);
  }

  const init = () => {
    if (document.querySelector('.calculator')) {
      document
        .getElementById('calculate')
        .addEventListener(`click`, calculateWater);
    }
  };

  init();
}
