{
	const postSpacing = 30;
	const newsPosts = Array.from(document.querySelectorAll(`#newsPost`));
	const post = document.getElementById('newsPost')
	const scrollAmount = post ? post.getBoundingClientRect().width + postSpacing : null;
	let maxScroll = (newsPosts.length * scrollAmount)-(scrollAmount*3);
	let scroll = scrollAmount;

	const handleScrollNext = () => {
		const posts = document.getElementById(`posts`);
		
		posts.scrollTo({
			top: 0,
			left: scroll,
			behavior: 'smooth'
		});

		if (scroll >= maxScroll) {
			scroll = maxScroll;
		} else {
			console.log("bijtellen");
			scroll+= scrollAmount;
		}
	}

	const handleScrollPrev = () => {
		const posts = document.getElementById(`posts`);
		
		posts.scrollTo({
			top: 0,
			left: scroll > scrollAmount ? scroll-(scrollAmount*2) : 0,
			behavior: 'smooth'
		});
		
		if (scroll <= scrollAmount) {
			scroll = scrollAmount;
		} else {
			scroll -= scrollAmount;
		}
	}

	const init = () => {
			const scrollNext = document.getElementById(`scrollNext`);
			const scrollPrev = document.getElementById(`scrollPrev`);

			if(scrollNext) {
				scrollNext.addEventListener(`click`, handleScrollNext);
				scrollPrev.addEventListener(`click`, handleScrollPrev);
			}
	};

	init();
}